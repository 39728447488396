/* Navbar */

.nav__mobile--btn-open {
  display: none;
  position: absolute;
  width: 5rem;
  top: 5rem;
  left: 80%;
}

.nav__mobile--btn-open svg {
  fill: var(--color-background-dark);
  opacity: 0.8;
}

.nav__mobile--btn-open:hover,
.nav__mobile--btn-close:hover {
  cursor: pointer;
}

.nav__mobile--btn-close {
  position: fixed;
  text-align: center;
  width: 5rem;
  top: 4rem;
  left: 80%;
  font-size: 3rem;
  color: var(--color-background-dark);
  font-weight: 700;
  opacity: 0.8;
}

.nav__mobile {
  display: none;
  position: fixed;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  background-color: var(--color-background);
  top: 0;
  overflow-x: hidden;
  transition: all 0.2s;
}

@keyframes popin {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}

.nav__mobile--open {
  display: flex !important;
  transition: all 0.2s;
  animation: popin 0.2s;
}

.logo--mobile {
  width: 7rem;
  position: absolute;
  top: 70%;
  left: 45%;
}

.nav__navbar--mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  padding: 5rem;
  display: flex;
  justify-content: center;
  margin-bottom: 20rem;
}

.nav__list--mobile {
  font-size: 2rem;
  font-weight: 600;
  display: inline-block;
  padding: 2rem 4rem;
}

.nav__list-item--mobile {
  margin-top: 2rem;
}

.nav__mobile--social {
  width: 30%;
  display: flex;
  justify-content: space-evenly;
  margin-top: 1rem;
}

.nav__mobile--social img {
  width: 3rem;
}

.nav__link--mobile {
  color: white;
  margin-top: 2rem;
  font-weight: 500;
  transition: all 0.2s;
  animation-name: fade-in;
  animation-duration: 1s;
}

.nav__link--mobile:hover,
.nav__link--mobile:active {
  color: var(--color-highlight);
}

.nav__container {
  display: flex;
  position: absolute;
  justify-content: space-evenly;
  align-items: center;
  width: 100vw;
  padding: 2rem 0;
}

.logo {
  display: inline-block;
  width: 7rem;
  height: 7rem;
}

.nav__navbar {
  width: 35vw;
  display: flex;
  justify-content: flex-end;
}

.nav__list-item {
  font-size: 1.8rem;
  display: inline-block;
  padding: 0 4rem;
}
.nav__link {
  color: white;
  font-weight: 500;
  transition: all 0.2s;
  animation-name: fade-in;
  animation-duration: 1s;
}

.nav__link:hover,
.nav__link:active {
  color: var(--color-highlight);
}

/* About */
.about__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  padding: 10rem 5rem;
  padding-top: 25rem;
  color: white;
}

.about__container-flex {
  display: flex;
  justify-content: center;
  padding: 0 10rem;
}

.about__img {
  display: flex;
  align-items: center;
  width: 22vw;
  animation: popup 1s, fade-in 4s;
}

.about__img img {
  width: 100%;
  box-shadow: -10px 10px 0px var(--color-highlight);
  animation: float 4s ease-in infinite;
}

@keyframes float {
  0% {
    box-shadow: -3px 4px 0px var(--color-highlight);
    transform: translatey(0px);
  }
  50% {
    box-shadow: -10px 10px 0px var(--color-highlight);
    transform: translatey(-10px);
  }
  100% {
    box-shadow: -3px 4px 0px var(--color-highlight);
    transform: translatey(0px);
  }
}

@keyframes lower {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes popup {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0%);
  }
}

.about__bio {
  min-width: 20%;
  max-width: 45%;
}

.about__heading {
  font-size: clamp(1.6rem, 1.2vw, 3.2rem);
  padding-left: 3rem;

  animation: fade-in 2.5s backwards, lower 1s backwards;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.heading__highlight {
  display: inline-block;
  color: var(--color-highlight);
}

.about__body {
  font-size: clamp(1.5rem, 1vw, 2.2rem);
  padding: 3rem;
  margin-top: 2rem;
  animation: fade-in 0.5s 1s backwards;
}

.about__body--highlight {
  color: var(--color-highlight);
  font-weight: 600;
}

.about__skills {
  z-index: 10;
  width: 40%;
  display: flex;
  justify-content: space-around;
  margin-top: 16rem;
  padding: 1rem 4rem;
}

.about__skills--decoration {
  display: inline-block;
  position: absolute;
  font-size: 9rem;
  font-weight: 700;
  letter-spacing: 5rem;
  margin-top: -9rem;
  margin-left: 4rem;
  color: var(--color-background-dark);
  opacity: 0.25;
  animation: fade-deco 0.7s ease-in 2s backwards 1;
}

@keyframes fade-deco {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.25;
  }
}

.about__skills img {
  display: inline-block;
  width: 5rem;
  z-index: 10;
  transition: all 1s;
  animation: fade-in 0.7s ease-in 1.5s backwards 1,
    popup 0.5s ease-in 1.5s backwards 1;
}

/* Works */
.works__container {
  width: 100vw;
  padding: 10rem 5rem;
  padding-top: 20rem;
  color: white;
}

.works__heading {
  font-size: 4rem;
  text-align: center;
}

.works__grid {
  display: grid;
  padding: 5rem 5rem;
  grid-template-columns: repeat(2, 1fr);
  gap: 5rem;
}

.work__container {
  position: relative;
  width: 100%;
  display: flex;
  padding: 2rem;
  padding-top: 4rem;
  background-color: var(--color-black);
  border-radius: 15px;
  box-shadow: -10px 9px 10px #000;
  animation: fade-in 0.2s ease-in;
}

.work {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-width: 40%;
}

.work__img {
  max-width: 100%;
}

.work__details {
  z-index: 20;
  min-width: 60%;
  padding: 0rem 2rem;
}

.work__heading {
  font-size: clamp(1.6rem, 1vw, 2.5rem);
  padding: 0rem 1rem;
  border-bottom: 2px solid var(--color-highlight);
}

.work__tag--wrapper {
  display: flex;
  flex-wrap: wrap;
  padding: 1rem 1rem;
}

.work__tag {
  color: var(--color-grey);
  font-size: 1.2rem;
  font-weight: 600;
  display: inline-block;
  margin-right: 1rem;
  margin-top: 1rem;
  background-color: var(--color-black-dark);
  padding: 0.5rem 1rem;
}

.work__body {
  font-size: clamp(1.4rem, 1vw, 1.6rem);
  padding: 1rem;
}

.work__links {
  padding: 2rem 0rem;
}

.work__link {
  font-size: 1.4rem;
  font-weight: 600;
  color: var(--color-highlight);
  margin-right: 1rem;
  padding: 1rem;
  border-radius: 5px;
  display: inline-block;
  transition: all 0.2s;
}

.work__link:hover {
  background-color: var(--color-highlight);
  transform: translateY(-5px);
  color: white;
  box-shadow: 1px 4px 5px rgba(0, 0, 0, 0.5);
}

.work__link:active {
  transform: translateY(-3px);
  box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.8);
}

.work__num {
  position: absolute;
  z-index: 10;
  padding: 2rem;
  font-size: clamp(10rem, 1vw, 15rem);
  font-weight: 700;
  right: 0;
  bottom: 0;
  color: var(--color-black-dark);
  opacity: 0.4;
}

/* Footer */
.footer__container {
  width: 100vw;
  padding: 2.5rem 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  color: var(--color-grey);
  font-size: 1.5rem;
  text-align: center;
}

.footer__text {
  animation: popup 0.5s ease-in 2s backwards, fade-in 0.5s ease-in 2s backwards;
}

.footer__text::before {
  display: inline-block;
  content: "";
  border-top: 0.2rem solid var(--color-highlight);
  width: 20rem;
  margin: -1rem 1rem;
  transform: translateY(-1rem);
}

.footer__text::after {
  display: inline-block;
  content: "";
  border-top: 0.2rem solid var(--color-highlight);
  width: 20rem;
  margin: -1rem 1rem;
  transform: translateY(-1rem);
}

.footer__links {
  display: flex;
  justify-content: space-evenly;
  margin-top: 1rem;
  width: 10rem;
}

.footer__links img,
.footer__links a {
  width: 3rem;
  transition: all 0.2s;
  opacity: 0.8;
  animation: popup 0.5s ease-in 2s backwards, fade-in 0.5s ease-in 2s backwards;
}

.footer__links img:hover,
.footer__links a:hover {
  margin-top: -2px;
}

/* Media Queries */
@media (max-width: 1800px) {
  .works__grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .work__container {
    flex-direction: column;
  }

  .work__details {
    padding: 2rem 2rem;
  }

  .work {
    padding: 2rem 2rem;
  }
}

@media (max-width: 1200px) {
  .about__img {
    width: 22vw;
  }

  .about__bio {
    max-width: 60%;
  }

  .about__skills {
    width: 50%;
    padding: 1rem 1rem;
  }

  .about__skills img {
    width: 4rem;
  }

  .works__grid {
    grid-template-columns: repeat(1, 70%);
    justify-content: center;
  }
}

@media (max-width: 920px) {
  .nav__mobile--btn-open {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: hidden;
  }

  .nav__container {
    display: none;
  }

  .about__bio {
    max-width: 80%;
  }

  .about__container-flex {
    padding: 0 2rem;
  }

  .about__skills {
    margin-top: 6rem;
  }

  .about__skills--decoration {
    display: none;
  }

  .works__container {
    padding: 10rem 1.5rem;
  }

  .works__grid {
    grid-template-columns: 1fr;
    padding: 2rem 0rem;
  }

  .footer__text::before {
    display: none;
  }

  .footer__text::after {
    display: none;
  }
}

@media (max-width: 800px) {
  .about__container {
    padding: 10rem 0rem;
  }

  .about__img {
    display: none;
  }

  .about__container-flex {
    padding: 0;
  }
  .about__bio {
    max-width: 100%;
  }

  .about__skills {
    width: 90%;
    padding: 0;
  }

  .about__skills img {
    width: 3rem;
  }

  .works__grid {
    grid-template-columns: 1fr;
    padding: 2rem 0rem;
  }

  .work {
    display: none;
  }
}
