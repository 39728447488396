@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap");

:root {
  --color-highlight: #cdbe78;
  --color-background: #096163;
  --color-background-dark: #022e2f;
  --color-white: #fff;
  --color-black: #383838;
  --color-black-dark: #1a1a1a;
  --color-grey: #f2f2f2;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: var(--color-background-dark) var(--color-background);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 16px;
}

*::-webkit-scrollbar-track {
  background: var(--color-background);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--color-highlight);
  border-radius: 10px;
  border: 3px solid var(--color-background);
}

html {
  font-family: "Montserrat", sans-serif;
  font-size: 62.5%;
  overflow-x: hidden;
  line-height: 1.6;
}

body {
  /* background-color: var(--color-black); */
  background-color: var(--color-background);
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}
